<template>
  <div>
    <b-card title="User Details">
      <validation-observer ref="basicValidation">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group label="User Name">
                <validation-provider #default="{ errors }" name="User Name" rules="required">
                  <b-form-input v-model="User.name" :state="errors.length > 0 ? false : null" placeholder="User Name" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Email">
                <validation-provider #default="{ errors }" name="Email" rules="required">
                  <b-form-input type="email" v-model="User.email" :state="errors.length > 0 ? false : null"
                    placeholder="Email" required />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Password">
                <validation-provider #default="{ errors }" name="Password" rules="required">
                  <b-form-input v-model="User.password" type="password" :state="errors.length > 0 ? false : null"
                    placeholder="Password" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Role">
                <b-form-select v-model="User.role" :options="roles" />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Gender">
                <b-form-select v-model="User.gender" placeholder="Gender" :options="genders" />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Phone">
                <b-form-input v-model="User.phone" type="number" placeholder="Phone" />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="City">
                <b-form-input v-model="User.city" placeholder="City" />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="State">
                <b-form-input v-model="User.state" placeholder="State" />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Country">
                <b-form-input v-model="User.country" placeholder="Country" />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="PinCode">
                <b-form-input v-model="User.pincode" placeholder="Pincode" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

    <b-card>
      <b-col class="align-self-center">
        <b-button @click="createUser" size="lg" block variant="primary">Add User</b-button>
      </b-col>
    </b-card>

  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import { getUserToken } from "@/auth/utils";
import CryptoJS from "crypto-js";

import {
  BCard,
  BRow,
  BCol,
  BForm,
  BTabs,
  BTab,
  BFormInput,
  BFormGroup,
  BButton,
  BFormTextarea,
  BFormFile,
  BCardText,
  BFormSelect
} from "bootstrap-vue";
import axios from "axios";
export default {
  directives: {
    Ripple,

  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BTabs,
    BTab,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BFormTextarea,
    BFormFile,
    BCardText,
    BFormSelect,
  },
  data() {
    return {
      required,
      file: [],
      uploadErrors: [],
      User: {
        name: "",
        email: "",
        password: "",
        newpassword: "",
        phone: "",
        role: "User",
        gender: "male",
        city: "",
        state: "",
        country: "",
        pincode: "",
        channel:"email"

      },
      roles: [
        { value: 'User', text: 'User' },
        { value: 'Agent', text: 'Agent', },
        { value: 'Resort-Manager', text: 'Resort Manager' },
        { value: 'Resort-Staff', text: 'Resort Staff' },
        { value: 'Sr-Booking-Officer', text: 'Sr. Booking Officer' },
        { value: 'Booking-Staff', text: 'Booking Staff' }
      ],
      genders: [
        { value: 'male', text: "Male" },
        { value: 'female', text: "Female" },
        { value: 'transgender', text: "Transgender" },
        { value: 'otherr', text: "Not Prefer to say" },
      ]

    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    createUser() {
      if (!this.User.name || !this.User.email || !this.User.password || !this.User.phone || !this.User.role) {
        console.log(this.User)
        return this.makeToast(
          "danger",
          "Error",
          'fields are required!'
        );
      }
  this.User.password= CryptoJS.AES.encrypt(JSON.stringify({encryptedPassword: this.User.password}), process.env.VUE_APP_SIGN_KEY).toString(),
  this.User.newpassword = this.User.password
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/org/user/create`,
          this.User,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.makeToast(
            "success",
            "Success",
            `${response.data.user.name} has been created successfully!`
          );
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast(
            "danger",
            "Error",
            "Error while adding the User!"
          );
        });
    }
  },
};
</script>
<style></style>